import { render, staticRenderFns } from "./addOther.vue?vue&type=template&id=0895055c&scoped=true&"
import script from "./addOther.vue?vue&type=script&lang=js&"
export * from "./addOther.vue?vue&type=script&lang=js&"
import style0 from "./addOther.vue?vue&type=style&index=0&id=0895055c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0895055c",
  null
  
)

export default component.exports